import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/3alL2EuLSSY">
    <SEO title="Holy Suffering - Touching Holiness" />
  </Layout>
)

export default SermonPost
